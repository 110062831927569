
























import Vue from "vue";
import { mapActions, mapGetters } from "vuex";
import {
  GET_USER_DETAILS,
  LOGOUT_USER,
  USER_STATE
} from "@/store/modules/auth/constants";
import { UserState } from "@/store/modules/auth/interfaces";
// import AppPoweredBy from "@/components/shared/AppPoweredBy.vue";

export default Vue.extend({
  // components: { AppPoweredBy },
  name: "AuthBoardingFooter",
  computed: {
    UserState() {
      return UserState;
    },
    ...mapGetters("auth", {
      user_state: USER_STATE,
      get_user_details: GET_USER_DETAILS
    })
  },
  methods: {
    ...mapActions("auth", {
      logout: LOGOUT_USER
    }),
    async _logout_user() {
      await this.logout();
      await this.$router.push("/auth/login");
    }
  }
});
