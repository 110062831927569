



















import Vue from "vue";
import ChangeLanguage from "@/components/shared/ChangeLanguage.vue";

export default Vue.extend({
  name: "AuthBoardingHeader",
  components: { ChangeLanguage },
  data() {
    return {
      hcms_logo: require("@/assets/logos/qudraTechBlack.svg")
    };
  }
});
